import React, { FC, PropsWithChildren } from 'react';

import { ViewProps } from '~/components/View/props';

import ViewTitle from '../Header/Title';
import ViewSplit from './Split';

const CMSView: FC<PropsWithChildren<ViewProps>> = props => {
  const { children, type, title, className } = props;

  switch (type) {
    case 'split':
      if (children && Array.isArray(children) && children.length > 1) {
        return <ViewSplit {...props} />;
      }

      return (
        <div className="mx-6 md:mx-12">
          Il manque un enfant pour afficher cette vue
        </div>
      );

    default:
      return (
        <div className={className || 'flex flex-col pb-6 md:mx-auto md:px-6'}>
          {title && <ViewTitle>{title}</ViewTitle>}
          {children}
        </div>
      );
  }
};

export default CMSView;
